<template>
  <div class="home">
    
    <div class="container-fluid">
      <principal-navbar class="row" />
    </div>

    <!-- PRODUCTS -->
    <principal-products />
    
  </div>
</template>

<script>
export default {
  name: 'Products',
  metaInfo() {
    return { 
      title: "Aceros Prolider SA de CV - Productos",
      meta: [
        { name: 'description', content:  'Somos expertos en la comercialización y distribución de acero inoxidable, aluminio y varios productos más como latón, bronce, cobre, nylon y acero negro.'},
        { property: 'og:title', content: "Aceros Prolider SA de CV - Productos"},
        { property: 'og:site_name', content: 'Aceros Prolider SA de CV - Productos'},
        { property: 'og:type', content: 'website'},
      ]
    }
  },
  components: {
    PrincipalNavbar: () => import('@/components/PrincipalNavbar.vue'),
    PrincipalProducts: () => import('@/components/PrincipalProducts.vue')
  }
}
</script>